import Vue from 'vue'
// import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
// import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'

import VueRippler from 'vue-rippler'

Vue.use(VueRippler)


import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
if(JSON.parse(localStorage.getItem('uTid')) != null)
{
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('uTid')).utid; 
}

import ClassOnScroll from 'vue-class-on-scroll'

import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
 
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

import VueSession from 'vue-session'
Vue.use(VueSession)

import VueShimmer from '@gomarky/vue-shimmer' // shimmer div
Vue.use(VueShimmer)

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

////////// Google Fire Base Analytics Start //////
import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfigNew = {
  apiKey: "AIzaSyB7ep4Tw6mngbFx2qEkJogvzEb-Ch7UUJk",
  authDomain: "cloudkitch-website.firebaseapp.com",
  projectId: "cloudkitch-website",
  storageBucket: "cloudkitch-website.appspot.com",
  messagingSenderId: "208162186639",
  appId: "1:208162186639:web:4f4fa3939c56258679d7fa",
  measurementId: "G-88M22VY6S3"
};

firebase.initializeApp(firebaseConfigNew);
firebase.analytics();

Vue.config.productionTip = false

// alias
Vue.prototype.$analytics = firebase.analytics();
////////// Google Fire Base Analytics End //////

// Vue.prototype.$userData = JSON.parse(localStorage.getItem('userData'));
//theme colors
Vue.prototype.$themeColorYellow = "#FFC60C";
Vue.prototype.$themeColorPink = "#F3766A";
Vue.prototype.$themeColorBrown = "#A4827D";

import GoogleLogin from 'vue-google-login';
Vue.component('GoogleLogin', GoogleLogin)

import '@/assets/scss/app.scss'
 
Vue.component('VueSlideBar', VueSlideBar)
// Vue.use(BootstrapVue)
Vue.use(vco)
// Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

// Vue.use(require("vue-chartist"));
Vue.use(VueMask);


// import VueResource from 'vue-resource'
// import VueFire from 'vuefire'

// Vue.use(VueResource)
// Vue.use(VueFire)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
