<template>
  <div id="app">
    <router-view />
    <!-- <push-notification
        ref="pushNotification"
        :currentToken="userToken"
        @update-token="onUpdateToken"
        @new-message="onNewMessage" /> -->

    <go-top :size="40" :right="20" :bottom="50" bg-color="#F3766A"></go-top>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import GoTop from "@inotom/vue-go-top";
import Vue from "vue";

// import PushNotification from '@/components/PushNotification'
// import api from '@/api/api'

// import VueRouter from 'vue-router'
// import VueHead from 'vue-head'

// Vue.use(VueHead)
// Vue.use(VueRouter)

// import VueMeta from 'vue-meta'
// Vue.use(VueMeta)

Vue.prototype.$userData = JSON.parse(localStorage.getItem("uTid"));
Vue.prototype.$placesAddress = JSON.parse(
  localStorage.getItem("placesAddress")
);
Vue.prototype.$ApiUrl = process.env.VUE_APP_BASE_URL;
// Vue.prototype.$ApiUrl = "";
Vue.prototype.$isExtMob = JSON.parse(localStorage.getItem("isExtMob"));

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      // return title ? `${title} | ${appConfig.title}` : appConfig.title;
      return title ? `${title} ` : appConfig.title;
    },
  },
  //   head: {
  //     // title: {
  // 	// 	inner: 'CloudKitch',
  // 	// 	separator: ' ',
  // 	// 	// complement: 'My Complement'
  // 	// },
  // 	meta:
  // 	[
  // 		{ name: 'application-name', content: 'CloudKitch' },
  //       	{ name: 'description', content: 'Great food experience is about thoughtful preparation. CloudKitch makes that happen for you.', id: 'desc' }, // id to replace intead of create element

  // 		{ itemprop: 'name', content: 'CloudKitch' },
  //       	{ itemprop: 'description', content: 'Great food experience is about thoughtful preparation. CloudKitch makes that happen for you.' },

  // 		{ property: 'og:title', content: 'CloudKitch' },
  // 		{ property: 'og:image', itemprop:"image", content: 'https://cloudkitch.co.in/images/logo.svg' },
  // 		{ property: 'og:url', content: 'app.cloudkitchdev.co.in' },
  // 		{ property: 'og:type', content: 'website' },
  // 		{ property: 'og:description', content: 'Great food experience is about thoughtful preparation. CloudKitch makes that happen for you.' },
  // 	],

  //   },
  // metaInfo: {
  //     title: 'CloudKitch',
  //     // titleTemplate: '%s | vue-meta Example App',
  //     htmlAttrs: {
  //       lang: 'en-US'
  //     },
  //     meta: [
  //       { name: 'description', content: 'Great food experience is about thoughtful preparation. CloudKitch makes that happen for youTest.' },
  //       { name: 'title', content: 'CloudKitchTest' },
  //     ]
  //   },

  // created () {

  // 	///////////// push noti permission ////////////////////
  //     var userLoggedId = 1
  //     // check if user has a token
  //     api.user_profile(userLoggedId).then((response) => {
  //       this.userProfile = response.data
  //       this.userToken = this.userProfile.push_notification.ask_for_permission.token
  //       if (this.userProfile.push_notification.ask_for_permission) {
  //         setTimeout(() => {
  //           // Simulate it wont ask for permission in the first user access
  //           this.askForPermission = true
  //         }, 4000)
  //       }
  //     })
  // 	///////////// push noti permission ////////////////////
  //   },

  methods: {
    clearNotification: notificationMethods.clear,

    ///////////// push noti permission ////////////////////
    // enableNotifications () {
    //   this.$refs.pushNotification.askForPermission()
    // },
    // onUpdateToken (newToken) {
    //   this.userToken = newToken
    //   // send token to the server
    //   api.update_token(this.userProfile, this.userToken)
    // },
    // onNewMessage (message) {
    //   var snackbarContainer = document.querySelector('#snackbar-message')
    //   var data = {
    //     message: message.notification.title + ': ' + message.notification.body,
    //     timeout: 10000,
    //     actionText: 'OK'
    //   }
    //   snackbarContainer.MaterialSnackbar.showSnackbar(data)
    // },
    ///////////// push noti permission ////////////////////
  },
  components: {
    GoTop,
    // PushNotification
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  mounted() {
    ///////////// push noti permission ////////////////////
    // if(this.$userData != null && this.$userData != '')
    // {
    // 	this.enableNotifications()
    // }
    ///////////// push noti permission ////////////////////
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  },
};
</script>
<style>
@import "./views/templateAssests/css/bootstrap_customized.min.css";
@import "./views/templateAssests/css/style.css";
@import "./views/templateAssests/css/custom.css";
@import "./views/templateAssests/css/home.css";
</style>
<style lang="scss">
@import "./views/templateAssests/sass/_variables.scss";
</style>
<style>
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  -webkit-appearance: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.footer {
  left: 0 !important;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.mdi-cart-outline {
  font-size: 30px;
  color: #a4827d;
}
.open_close {
  margin-top: 15px !important;
}
/* .changeCafe{
			margin-left:4px;
		} */
.vegFood {
  background: #9fc925;
}
.veganFood {
  background: #5b7315;
}
.halalFood {
  background: #98060f;
}
.hrBorder {
  border: 1px solid #f5f5f5;
}
.total {
  font-size: 17px !important;
  color: #222222;
}
/* .total span{
			margin-right: 12px;
		} */
.foodType {
  border-radius: 13px;
  width: 25px;
  height: 25px;
  color: #fff;
  padding-top: 6px;
  display: inline-block;
  font-size: 10px;
  margin-right: 2px;
}
.foodTypeModal {
  border-radius: 12px;
  /* max-width:83px; */
  max-width: fit-content;
  min-width: 48px;
  /* padding: 1px; */
  padding-top: 1px;
  padding-right: 10px;
  padding-left: 10px;
  height: 23px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  margin-right: 8px;
  margin-bottom: 12px;
}
.socialBtnsDiv {
  border-radius: 20px;
  width: 30px;
  height: 30px;
  color: #fff;
  padding-top: 6px;
  display: inline-block;
  font-size: 10px;
  margin-right: 2px;
}
.socialBtnsDiv img {
  object-fit: contain;
}
.dishServings {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  margin-right: 9px;
}
.cartItems h4 {
  font-family: "GothamMedium" !important;
  font-size: 18px;
  color: #000 !important;
  margin-bottom: 13px;
}
.customizeAddons {
  /* font-size:11.7px; */
  font-size: 12px;
}
.head {
  position: relative;
  top: 14px;
  border-radius: 0 !important;
  background-color: #fef1f0 !important;
  color: #000 !important;
  text-align: left !important;
  /* padding :12px 0px 12px 24px !important; */
  /* padding :8px 20px !important; */
}
.restaurantsDetails h5 {
  font-size: 17px;
  color: #000 !important;
}
.locationDetails p {
  margin-bottom: 0;
  margin-top: 0;
  color: #a6a1a1;
}
.cartItems h4 {
  font-family: "GothamMedium" !important;
  font-size: 18px;
  color: #000 !important;
  margin-bottom: 13px;
}
.dishServings img {
  margin-right: 5px;
}
a.menu_item {
  min-height: 178px;
}
a.menu_item figure {
  width: 75px;
  height: 75px;
}
/* .dishDetails{
			border-bottom:1px solid #ededed;
		} */
.couponsSection {
  border: 1px solid #ededed;
  padding: 5px 15px 5px;
}
.mdi-ticket-percent-outline {
  font-size: 20px;
}
.couponText {
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
.walletSection {
  border: 1px solid #ededed;
  padding: 5px 15px 5px;
}
.mdi-wallet-outline {
  font-size: 20px;
}
.walletText {
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}
/* .walletSection a{
			position: relative;
    		left: 55px;
		} */
.opt_order {
  border-bottom: transparent;
  margin-bottom: 0px;
}
.dishName h5 {
  font-size: 15px;
  color: #222222;
  margin-bottom: 1px;
  margin-right: 15px;
}
.dishName {
  height: fit-content;
}
.dishTypes {
  margin-bottom: 10px;
}
.customAnch {
  color: #f3766a !important;
  cursor: pointer;
}
.addBtn {
  border: 1px solid #ced4da;
  background: transparent;
  color: #000;
  border-radius: 20px;
  width: 80px !important;
  height: 30px !important;
  line-height: 30px !important;
  padding: 0px !important;
  /* position:absolute;
			bottom:20px;
			right:20px; */
}
.addBtn:hover {
  background: #f3766a !important;
  color: #fff;
}
.discountTag {
  position: absolute;
  bottom: 19px;
  left: 68px;
}
.discountTag .discountText {
  position: relative;
  right: 54px;
  color: #fff;
  font-size: 10px;
}
.sectionText h4 {
  font-size: 24px;
  color: #212121;
}
.dishPrice {
  position: absolute;
  bottom: 20px;
  /* left: 20px; */
  color: #424242 !important;
}
.totalDish button {
  background: transparent !important;
  color: #212121 !important;
}
/* .modal-body{
			padding: 35px 25px 47px 26px!important;
		} */
.menuDetails h5 {
  font-size: 16px;
  margin-bottom: 5px;
}
.menuDetails p {
  font-size: 14px;
  margin-bottom: 10px;
}
.menu_item h3 {
  color: #222222;
  margin-bottom: 5px;
}
.totalDish button {
  background: transparent !important;
  color: #212121 !important;
}
.mobileBasketDiv {
  background-color: #ffc60c !important;
  z-index: 9 !important;
  padding: 10px;
}
.sidebar_fixed_mobile {
  padding: 0px !important;
}
#modal-viewDish___BV_modal_header_ {
  border-bottom: transparent;
}
#modal-cuisinePopup___BV_modal_header_ {
  border-bottom: transparent;
}
.cartItemsCharges {
  color: #fff;
}
.cartItemsCharges {
  margin-bottom: 0px !important;
}
.viewCartItems button {
  background: #fff !important;
  border-radius: 20px;
  color: #f3766a !important;
  padding: 0.47rem 1rem;
  min-width: 104px;
}
.one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cartTotalItemDetails {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  /* min-height: 60px; */
}
/* .cartTotalItemDetails::-webkit-scrollbar 
		{
    		display: none;
		} */
.cartTotalItemDetails::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.cartTotalItemDetails::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e0e0e0;
  border: 2px solid #e0e0e0;
}

.swal2-confirm {
  background-color: #ffc60c !important;
}
.margin_20_20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.page_header {
  padding: 17px 0;
}
.cartItemsMob {
  margin-bottom: 0;
}
.vue-go-top {
  z-index: 999 !important;
}
.fssaiSection {
  padding: 30px 0;
}
.mobVideo {
  display: none;
}
.webVideo {
  display: block;
}
@media (max-width: 991px) {
  .viewCartItems {
    /* padding-top: calc(var(--bs-gutter-x) / 2);
    		padding-bottom: calc(var(--bs-gutter-x) / 2);
			padding-right: 0px; */
    padding: 5px 10px;
  }
  .secondary_nav {
    padding: 10px 0 !important;
  }
  .mobVideo {
    display: block;
  }
  .webVideo {
    display: none;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 90% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 769px) {
  .noMobile {
    display: none !important;
  }
  .mainDivCuisineDetails {
    padding-top: 8px;
  }
}
</style>